import { HTTP } from "@/libs/https.js"
import config from "@/libs/config.js"

class SalaryProjectModel extends HTTP {
    getSalaryProjectList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSalaryProjectDelete(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_DELETE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
    setSwitchingStatus(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_SWITCHING_STATUS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
    getSalaryProjectDetailList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_DETAIL_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getQueryTeacherSalaryList(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_QUERY_TEACHER_SALARY_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getSalaryProjectDetailDelete(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_DETAIL_DELETE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getSalaryProjectDetailIssue(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_DETAIL_ISSUE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getSalaryProjectDetailEdit(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SALARY_PROJECT_DETAIL_EDIT,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getCompensationItems(data){
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_COMPENSATION_ITEMS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    createCompensationPrograms(data){
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.CREATING_COMPENSATION_PROGRAMS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getCompensationType(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_COMPENSATION_TYPE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    setCompensationItemsEmpty(data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_COMPENSATION_ITEMS_EMPTY,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    checkUploadRecord (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.CHECK_UPLOAD_RECORD,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    delCompensationItems (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DEL_COMPENSATION_ITEMS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getPeopleInfo (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PEOPLE_INFO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getTotalAverage (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TOTAL_AVERAGE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getTrendAnalysis (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TREND_ANALYSIS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getTrendAnalysisQuery (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_TREND_ANALYSIS_QUERY,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getAnalysisDetail (data){
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ANALYSIS_DETAIL,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
}

export { SalaryProjectModel }
