import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';


class SchoolDormitory extends HTTP {
    getSchoolDormitoryList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_DORMITORY_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolDormitorySortSave(data) {
        // return new Promise((resolve, reject) => {
        //     this.axiosPostArray({
        //         url: config.API.GET_ROOM_TOTAL_SORT_SAVE,
        //         data: data,
        //         header: 'application/json',
        //         success(data) {
        //             resolve({
        //                 data,
        //                 status: 0
        //             });
        //         },
        //         error(error) {
        //             resolve({
        //                 error,
        //                 status: -1
        //             })
        //         }
        //     });
        // });
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ROOM_TOTAL_SORT_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolDormitoryListCondition(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_DORMITORY_LIST_CONDITION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolDormitoryFloorListCondition(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_DORMITORY_FLOOR_LIST_CONDITION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getDormitoryOrganList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DORMITORY_ORGAN_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getStuListByDormitory(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_STU_LIST_BY_DORMITORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    saveSchoolDormitory(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_SCHOOL_DORMITORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deleteSchoolDormitory(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_SCHOOL_DORMITORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    downLoadSchoolDormitoryTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.DOWNLOAD_SCHOOL_DORMITORY_TEMPLATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    uploadSchoolDormitory(data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.UPLOAD_SCHOOL_DORMITORY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getRoomTotal(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ROOM_TOTAL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getRoomTotalDistribution(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ROOM_TOTAL_DISTRIBUTION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolDormitoryRoomList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_DORMITORY_LIST_ROOM,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getSchoolDormitoryStudentList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCHOOL_STUDENT_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    saveSchoolDormitoryRoom(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_SCHOOL_DORMITORY_ROOM,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deleteSchoolDormitoryRoom(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_SCHOOL_DORMITORY_ROOM,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    downLoadSchoolDormitoryRoomTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.DOWNLOAD_SCHOOL_DORMITORY_ROOM_TEMPLATE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    schoolDormitoryRoomDistribute(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SCHOOL_DORMITORY_ROOM_DISTRIBUTE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getDormitoryDistributionList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_DORMITORY_DISTRIBUTION_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getDormitoryList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_DORMITORY_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    deleteDormitoryDistribution(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.DELETE_DORMITORY_DISTRIBUTION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getRoomDistributeList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_ROOM_DISTRIBUTE_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    saveRoomDistribute(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SAVE_ROOM_DISTRIBUTE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getAttendanceDetails(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_ATTENDANCE_DETAILS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getAttendanceDetailStatistics(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ATTENDANCE_DETAIL_STATISTICS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

    getdDormitoryBedNumStatus(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_DORMOTORY_MORAL_BED_NUM_STATUS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
}

export { SchoolDormitory };
